import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RemoveTagHtml from '../../service/RemoveTagHtml';

function Layout1() {
    const [bgImgCover, setBgImgCover] = useState();
    const [title, setTitle] = useState();
    const cover = useSelector((state) => state.counter.value);
    function showTyping(typewrite) {
        var TxtType = function(el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 2000;
            this.txt = '';
            this.tick();
        };
        TxtType.prototype.tick = function() {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];
            this.txt = fullTxt.substring(0, this.txt.length + 1);
            this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
            var that = this;
            var delta = 200 - Math.random() * 100;
            if (!(this.txt === fullTxt)) {
                setTimeout(function() {
                    that.tick();
                }, delta);
            }
        };
        var elements = document.getElementsByClassName('typewrite')[0];
        var toRotate = `["${typewrite}"]`;
        var period = '2000';
        if (toRotate) new TxtType(elements, JSON.parse(toRotate), period);
    }
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let about = payload.about;
            setBgImgCover(`url(${payload.background.thumbnail})`);
            setTitle(RemoveTagHtml(about.title));
            showTyping(RemoveTagHtml(about.typewrite));
        }
    }, [cover]);

    return (
        <>
            <section id="hero" className="d-flex flex-column justify-content-center align-items-center" style={{backgroundImage: bgImgCover}}>
                <div className="hero-container">
                    <p>
                        <span className="typewrite"></span>
                    </p>
                    <h1>{title}</h1>
                </div>
            </section>
        </>
    );
}
export default Layout1;
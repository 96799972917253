import { useState, useEffect } from 'react';
// import NavBar from '../component/NavBar';
import Layout1 from '../component/Layout1';
import Main from '../component/Main';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';
import ServiceRequest from '../../service/ServiceRequest';
import MainService from '../../service/MainService';
import { Language } from '@react-lang/language';
function LandingPage() {
    const [loading, setLoading] = useState((
        <div className='card__image loading'>
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ));
    const dispacth = useDispatch();
    const requestCallback = (response) => {
        if(response) {
            let data = {
                background: response.background,
                ourBusinessList: response.ourBusinessList,
                about: response.about,
                client: response.client,
                portfolio: response.galery,
                address: response.address
            }
            dispacth(getData(data))
            setLoading('');
        }
    }
    const requestService = (lang) => {
        ServiceRequest(MainService(lang), requestCallback);
    }

    return (
        <div class="d-none"></div>
    );
}
export default LandingPage;
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Language } from '@react-lang/language';
function About() {
    const [ourBusiness, setOurBusiness] = useState();
    const [ourBusinessList, setOurBusinessList] = useState();
    const cover = useSelector((state) => state.counter.value);
    const businessImageHtml = (fileName) => {
        return (
            <div class="col-lg-6 content aos-init aos-animate">
                <img src={fileName} className="img-fluid" alt=""/>
            </div>
        );
    }
    const productList = (details) => {
        return details.map(value => {
            return (
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 682.667 682.667" className="feather feather-menu">
                        <g><defs><clipPath id="b" clipPathUnits="userSpaceOnUse"><path d="M0 512h512V0H0Z" fill="#000000" opacity="1" data-original="#000000"></path></clipPath></defs><mask id="a"><rect width="100%" height="100%" fill="#ffffff" opacity="1" data-original="#ffffff"></rect></mask><g mask="url(#a)"><path d="m0 0-134.174-134.174-63.873 63.872" style={{"stroke-width":40, "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-miterlimit": 10, "stroke-dasharray": "none", "stroke-opacity": 1}} transform="matrix(1.33333 0 0 -1.33333 473.365 251.884)" fill="none" stroke="#000000" stroke-width="40" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#000000"></path><g clip-path="url(#b)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)"><path d="M0 0c0-130.339-105.661-236-236-236S-472-130.339-472 0s105.661 236 236 236S0 130.339 0 0Z" style={{"stroke-width":40, "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-miterlimit": 10, "stroke-dasharray": "none", "stroke-opacity": 1}} transform="translate(492 256)" fill="none" stroke="#000000" stroke-width="40" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#000000"></path></g></g></g>
                    </svg>
                    <span>{value}</span>
                </li>
            )
        });
    }
    const businessDescriptionHtml = (id, name, description, details) => {
        return (
            <div className="col-lg-6 aos-init aos-animate">
                <h4>{name}</h4>
                <p>{description}</p>
                <ul>
                    {productList(details)}
                </ul>
                <a href="#" className="read-more">
                    <span>Read More</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512.009 512.009" className="feather feather-menu">
                        <g><path d="M508.625 247.801 392.262 131.437c-4.18-4.881-11.526-5.45-16.407-1.269-4.881 4.18-5.45 11.526-1.269 16.407.39.455.814.88 1.269 1.269l96.465 96.582H11.636C5.21 244.426 0 249.636 0 256.063s5.21 11.636 11.636 11.636H472.32l-96.465 96.465c-4.881 4.18-5.45 11.526-1.269 16.407s11.526 5.45 16.407 1.269c.455-.39.88-.814 1.269-1.269l116.364-116.364c4.511-4.537 4.511-11.867-.001-16.406z" fill="#000000" opacity="1" data-original="#000000"></path></g>
                    </svg>
                </a>
            </div>
        );
    }
    const ourBusinessHtm = ourBusinessList?.map( (value, index) => {
        return (
            <div className="row gy-4" id={`ourBusiness${value.id}`} key={index}>
                {index % 2 == 0 ? businessImageHtml(value.fileName) : businessDescriptionHtml(value.id, value.name, value.description, value.details)}
                {index % 2 == 1 ? businessImageHtml(value.fileName) : businessDescriptionHtml(value.id, value.name, value.description, value.details)}
            </div>
        )
    });
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            setOurBusiness(payload.about.our_business);
            setOurBusinessList(payload.ourBusinessList);
        }
    }, [cover]);
    return (
        <Language.Consumer>
            {({get}) => 
                <section id="about" className="about section">
                    <div className="container section-title aos-animate text-center">
                        <h2>{get('ourBusiness')}</h2>
                        {ReactHtmlParser(ourBusiness)}
                    </div>
                    <div className="container">
                        <div className="row gy-4">
                            {ourBusinessHtm}
                        </div>
                    </div>
                </section>
            }
        </Language.Consumer>
    );
}
export default About;
import { Outlet } from "react-router-dom";
import ScrollTop from '../component/ScrollTop';
import NavBar from '../component/NavBar';
function Main() {
    return (
        <>
            <NavBar />
            <main id="main" className="main">
                <Outlet />
                <ScrollTop />
            </main>
        </>
    );
}
export default Main;
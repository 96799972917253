import { useState, useEffect } from 'react';
import Layout1 from '../component/Layout1';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';
import ServiceRequest from '../../service/ServiceRequest';
import MainService from '../../service/MainService';
import { Language } from '@react-lang/language';
import { Outlet } from "react-router-dom";
function LandingPage() {
    const [loading, setLoading] = useState((
        <div className='card__image loading'>
            <div className="spinner-border">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ));
    const dispacth = useDispatch();
    const requestCallback = (response) => {
        if(response) {
            let data = {
                background: response.background,
                ourBusinessList: response.ourBusinessList,
                about: response.about,
                client: response.client,
                portfolio: response.galery,
                address: response.address
            }
            dispacth(getData(data))
            setLoading('');
        }
    }
    const requestService = (lang) => {
        ServiceRequest(MainService(lang), requestCallback);
    }

    return (
        <Language.Consumer>
            {({lang}) => 
                <>
                    {requestService(lang)}
                    {loading}
                    <Layout1 />
                    <Outlet />
                </>
            }
        </Language.Consumer>
    );
}
export default LandingPage;
import { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.png';
import { useSelector } from 'react-redux';
import unitedKingdom from '../../assets/img/united-kingdom.png';
import indonesia from '../../assets/img/indonesia.png';
import { Language } from '@react-lang/language';
import { useNavigate, useLocation } from "react-router-dom";
import ServiceRequest from '../../service/ServiceRequest';
import MainService from '../../service/MainService';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';

function NavBar() {
    const location = useLocation();
    const [bgImgCover, setBgImgCover] = useState();
    const [ourBusinessList, setOurBusinessList] = useState();
    const [lang, setLang] = useState({
        selected: {
            src: indonesia,
            label: 'Indonesian',
            alias: 'id'
        },
        other: {
            src: unitedKingdom,
            label: 'English',
            alias: 'en'
        }
    });
    const cover = useSelector((state) => state.counter.value);
    const navigate = useNavigate();
    const dispacth = useDispatch();
    const hoverAnotherMenu = () => {
        hoverBusinessLine(false);
        hoverLang(false);
    }
    const hoverBusinessLine = (val) => {
        let companyItem = document.getElementById('companyItem').classList;
        let companyMenu = document.getElementById('companyMenu').classList;
        if(val) {
            companyItem.add('show');
            companyMenu.add('show');
            hoverLang(false);
        }
        else {
            companyItem.remove('show');
            companyMenu.remove('show');
        }
    };
    const hoverLang = (val) => {
        let langBox = document.getElementById('langBox').classList;
        let langBoxMenu = document.getElementById('langBoxMenu').classList;
        if(val) {
            langBox.add('show');
            langBoxMenu.add('show');
            hoverBusinessLine(false);
        }
        else {
            langBox.remove('show');
            langBoxMenu.remove('show');
        }
    };
    const showMenu = () => {
        document.getElementById('navbarMarketing').classList.toggle('show');
        document.getElementById('navbarSupportedContent').classList.toggle('show');
    }
    const updateLang = (alias, handleSetLanguage) => {
        handleSetLanguage(alias);
        let tmp = {
            selected: lang.other,
            other: lang.selected
        };
        setLang(tmp);
        hoverLang(false);
        document.getElementById('navbarMarketing').classList.remove('show');
        document.getElementById('navbarSupportedContent').classList.remove('show');
        ServiceRequest(MainService(alias), requestCallback);
    };
    const requestCallback = (response) => {
        if(response) {
            let data = {
                background: response.background,
                ourBusinessList: response.ourBusinessList,
                about: response.about,
                client: response.client,
                portfolio: response.galery,
                address: response.address
            }
            dispacth(getData(data));
        }
    }
    const goToScroll = (value) => {
        if(location.pathname != "/demo") {
            handleNavigate('/demo');
            setTimeout(() => handleGoToScroll(value.target.value), 300);
        }
        else {
            handleGoToScroll(value.target.value);
        }
    }
    const handleGoToScroll = (value) => {
        let element = document.getElementById(value);
        if(element) {
            element.scrollIntoView({ behavior: 'smooth' });
            document.getElementById('companyItem').classList.remove('show');
            document.getElementById('companyMenu').classList.remove('show');
            document.getElementById('langBox').classList.remove('show');
            document.getElementById('langBoxMenu').classList.remove('show');
            document.getElementById('navbarMarketing').classList.remove('show');
            document.getElementById('navbarSupportedContent').classList.remove('show');
        }
    }
    const handleNavigate = (val) => {
        navigate(val);
    };
    const handleScroll = () => {
        let ourBusiness = document.querySelectorAll('[id^=ourBusiness]');
        if(ourBusiness) {
            // ourBusiness.forEach((val) => {
            //     let boundingClientRect = val.getBoundingClientRect();
            //     if(boundingClientRect.top <= boundingClientRect.height) {

            //     }
            // });
            // const scrollTop = document.documentElement.scrollTop;
            // const scrollHeight = document.documentElement.scrollHeight;
            // const clientHeight = document.documentElement.clientHeight;
            // const position = Math.ceil(
            //     (scrollTop / (scrollHeight - clientHeight))*100
            // );
            // // console.log(position);ourBusiness26
            // console.log(document.getElementById('ourBusiness26').getBoundingClientRect());
            // console.log(document.documentElement.getBoundingClientRect());
            // console.log(scrollHeight);
            // console.log(clientHeight);
        }
        let navbarMarketing = document.getElementById('navbarMarketing');
        let scrollTop = document.getElementById('scroll-top');
        let boundingClientRect = navbarMarketing.getBoundingClientRect();
        if(document.documentElement.scrollTop > boundingClientRect.height) {
            navbarMarketing.classList.add('scrolled');
            scrollTop.classList.add('active');
        }
        else {
            navbarMarketing.classList.remove('scrolled');
            scrollTop.classList.remove('active');
        }
    };
    const ourBusinessHtm = ourBusinessList?.map( (value, index) => {
        return (
            <button type="button" className="dropdown-item" onClick={goToScroll} value={`ourBusiness${value.id}`} key={index}>{value.name}</button>
        )
    });
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            setBgImgCover(`url(${payload.background.cover_galery})`);
            setOurBusinessList(payload.ourBusinessList);
            document.getElementById('main').addEventListener('mouseover', () => hoverAnotherMenu());
            document.getElementById('hero').addEventListener('mouseover', () => hoverAnotherMenu());
        }
    }, [cover]);
    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <nav id="navbarMarketing" className="navbar navbar-marketing navbar-expand-lg bg-transparent navbar-light fixed-top">
                <div className="container px-5">
                    <button className="navbar-brand text-primary" onClick={() => handleNavigate("/demo")}>
                        <img src={logo} className="img-fluid" alt="Logo"></img>
                    </button>
                    <button type="button" className="navbar-toggler" onClick={showMenu}>
                        <div className="eof-c28">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="feather feather-menu">
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </div>
                    </button>
                    <Language.Consumer>
                        {({get, handleSetLanguage}) => 
                            <div id="navbarSupportedContent" className="collapse navbar-collapse">
                                <ul className="navbar-nav ms-auto me-lg-5">
                                    <li className="nav-item">
                                        <button type="button" className="nav-link" onClick={goToScroll} value="our-top-clientele" onMouseEnter={() => hoverAnotherMenu()}>{get('about')}</button>
                                    </li>
                                    <li id="companyItem" className="nav-item dropdown-xl no-caret dropdown">
                                        <button type="button" className="dropdown-toggle nav-link pointer" onMouseEnter={() => hoverBusinessLine(true)}>
                                            {get('businessLine')}
                                            <div className="ng-fa-icon dropdown-arrow">
                                                <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                                                </svg>
                                            </div>
                                        </button>
                                        <div id="companyMenu" className="dropdown-menu dropdown-menu-end animated--fade-in-up me-xl-n15">
                                            <div className="row gx-0">
                                                {/* <div className="col-lg-5 p-lg-3 bg-img-cover overlay overlay-primary overlay-70 d-none d-lg-block" style={{backgroundImage: bgImgCover}}>
                                                    <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                                                        <div className="text-white text-center z-1">
                                                            <div className="mb-3">{get('someOfOur')}</div>
                                                            <a className="btn btn-white btn-sm text-primary" href="/">{get('viewAll')}</a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-12 p-lg-5">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <button type="button" className="dropdown-item" onClick={goToScroll} value='visi'>{get('vision')}</button>
                                                            <button type="button" className="dropdown-item" onClick={goToScroll} value='misi'>{get('mision')}</button>
                                                            <div className="dropdown-divider border-0 d-lg-none"></div>
                                                        </div>
                                                        {/* <div className="col-lg-6">
                                                            <h6 className="dropdown-header text-primary">{get('ourBusiness')}</h6>
                                                            {ourBusinessHtm}
                                                            <div className="dropdown-divider border-0 d-lg-none"></div>
                                                        </div> */}
                                                    </div>
                                                    
                                        <div className="dropdown-menu dropdown-menu-end animated--fade-in-up me-lg-n25 me-xl-n15 show">
                                            <div className="row gx-0">
                                                <div className="col-lg-12 p-lg-5">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h6 className="dropdown-header text-primary">{get('ourBusiness')}</h6>
                                                            {ourBusinessHtm}
                                                            <div className="dropdown-divider border-0 d-lg-none"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <button type="button" className="nav-link" onClick={goToScroll} value="our-top-clientele" onMouseEnter={() => hoverAnotherMenu()}>{get('ourTopClientele')}</button>
                                    </li>
                                    <li className="nav-item">
                                        <button type="button" className="nav-link" onClick={goToScroll} value="services" onMouseEnter={() => hoverAnotherMenu()}>{get('connectWithUs')}</button>
                                    </li>
                                </ul>
                                <div className="lang_box ms-lg-4" id="langBox">
                                    <button type="button" className="nav-link" onMouseEnter={() => hoverLang(true)}>
                                        <img src={lang.selected.src} alt="flag" className="mr-2 " title="United Kingdom"/>
                                        {lang.selected.label}
                                        <div className="ng-fa-icon dropdown-arrow">
                                            <svg role="img" focusable="false" width="21" height="21" className="svg-inline--fa fa-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path></svg>
                                        </div>
                                    </button>
                                    <div className="dropdown-menu" id="langBoxMenu">
                                        <button type="button" className="dropdown-item" onClick={() => updateLang(lang.other.alias, handleSetLanguage)}>
                                            <img src={lang.other.src} className="mr-2" alt="flag"/>
                                            {lang.other.label}
                                        </button>
                                    </div>
                                </div>
                                <button className="btn btn-primary fw-500 ms-lg-4" onClick={() => handleNavigate("/demo/contact-us")}>
                                    {get('contactNow')}
                                    <div className="ms-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 492.004 492.004" className="feather feather-menu">
                                            <g><path d="M484.14 226.886 306.46 49.202c-5.072-5.072-11.832-7.856-19.04-7.856-7.216 0-13.972 2.788-19.044 7.856l-16.132 16.136c-5.068 5.064-7.86 11.828-7.86 19.04 0 7.208 2.792 14.2 7.86 19.264L355.9 207.526H26.58C11.732 207.526 0 219.15 0 234.002v22.812c0 14.852 11.732 27.648 26.58 27.648h330.496L252.248 388.926c-5.068 5.072-7.86 11.652-7.86 18.864 0 7.204 2.792 13.88 7.86 18.948l16.132 16.084c5.072 5.072 11.828 7.836 19.044 7.836 7.208 0 13.968-2.8 19.04-7.872l177.68-177.68c5.084-5.088 7.88-11.88 7.86-19.1.016-7.244-2.776-14.04-7.864-19.12z" fill="currentColor" opacity="1"></path></g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        }
                    </Language.Consumer>
                </div>
            </nav>
        </>
    );
}
export default NavBar;